import React, { Fragment, useEffect, useState } from "react";
import {Table} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { CSVLink } from "react-csv";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { registeredUsersColumns, usersHeader } from "../../constants";
import { Button, Grid, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useUsersContext } from "../../../context/UsersContext";
import { getRegisteredUsersAll } from "../../../apis/dashboard";

export default function UsersTable({ allUsers, headerTitle }) {
  const {
    handleGetAllUsers,
    handleGetPersonalUsers,
    handleGetBusinessUsers,
    totalUsersCurrentPage,
    setTotalUsersCurrentPage,
    personalUsersCurrentPage,
    setPersonalUsersCurrentPage,
    businessUsersCurrentPage,
    setBusinessUsersCurrentPage,
    totalUsersRowsPage,
    setTotlalUsersRowPage,
    personalUsersRowsPage,
    setPersonalUsersRowPage,
    businessUsersRowsPage,
    setBusinessUsersRowPage,
    loading,
    totalUsersTableDetails,
    businessUsersTableDetails,
    personalUsersTableDetails,
  } = useUsersContext();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allRegisteredUsers, setAllRegisteredUsers] =useState([]);
  const [csvLoading, setCsvLoading] =useState(false);

  const handleChangePage = (event, newPage) => {
    headerTitle === "Registered Users"
      ? setTotalUsersCurrentPage(newPage + 1)
      : headerTitle === "Business Accounts"
      ? setBusinessUsersCurrentPage(newPage + 1)
      : headerTitle === "Personal Accounts"
      ? setPersonalUsersCurrentPage(newPage + 1)
      : headerTitle === "Deactivated Accounts"
      ? setPage(newPage)
      : setPersonalUsersCurrentPage(newPage + 1);
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (headerTitle === "Registered Users") {
      setTotlalUsersRowPage(+event.target.value);
      handleGetAllUsers(+event.target.value);
    } else if (headerTitle === "Business Accounts") {
      setBusinessUsersRowPage(+event.target.value);
      handleGetBusinessUsers(+event.target.value);
    } else if (headerTitle === "Personal Accounts") {
      setPersonalUsersRowPage(+event.target.value);
      handleGetPersonalUsers(+event.target.value);
    } else if (headerTitle === "Deactivated Accounts") {
      setRowsPerPage(+event.target.value);
      setPage(0);
    } else {
      setPersonalUsersRowPage(+event.target.value);
      handleGetPersonalUsers(+event.target.value);
    }

    // headerTitle === "Registered Users"
    //   ? () => {
    //       setTotlalUsersRowPage(+event.target.value);
    //       handleGetAllUsers(+event.target.value);
    //     }
    //   : headerTitle === "Business Accounts"
    //   ? () => {
    //       setBusinessUsersRowPage(+event.target.value);
    //       handleGetBusinessUsers(+event.target.value);
    //     }
    //   : headerTitle === "Personal Accounts"
    //   ? () => {
    //       setPersonalUsersRowPage(+event.target.value);
    //       handleGetPersonalUsers(+event.target.value);
    //     }
    //   : headerTitle === "Deactivated Accounts"
    //   ? () => {
    //       setRowsPerPage(+event.target.value);
    //       setPage(0);
    //     }
    //   : () => {
    //       setPersonalUsersRowPage(+event.target.value);
    //       handleGetPersonalUsers(+event.target.value);
    //     };

    // setRowsPerPage(+event.target.value);
    // setPage(0);
  };

  const handleGetAllRegisteredUsers =async () =>{
    setCsvLoading(true)
    const data = await getRegisteredUsersAll();
    setAllRegisteredUsers(data)
    setCsvLoading(false)
  }

  useEffect(()=>{ 
    handleGetAllRegisteredUsers();
  },[])

  return (
    <Fragment>
      {loading ? (
        <Box style={{ textAlign: "center", width: "100%" }}>
          <CircularProgress
            color="secondary"
            style={{
              color: "rgb(200, 75, 150)",
              width: "150px",
              height: "150px",
            }}
          />
        </Box>
      ) : (
        <Table style={{ border: "none" }}>
          <TableHead style={{ border: "none" }}>
            <TableRow>
              {registeredUsersColumns &&
                registeredUsersColumns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      border: "none",
                      fontSize: 12,
                      fontWeight: 600,
                      color: "#8086A2",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers &&
              allUsers.length > 1 &&
              allUsers
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Fragment>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        style={{ borderRadius: 20 }}
                      >
                        {registeredUsersColumns.map((column) => {
                          const value =
                            headerTitle === "Deactivated Accounts"
                              ? row.user && row.user[column.id]
                              : row[column.id];
                          // console.log(row.name)
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                border: "none",
                                background: "#F6F6F6",
                                color:
                                  headerTitle == "Deactivated Accounts"
                                    ? "#FD355A"
                                    : "#C84B96",
                                fontSize: 14,
                                fontWeight: column.id == "name" ? 600 : 500,
                                borderRadius:
                                  column.id == "name"
                                    ? "8px 0px 0px 8px"
                                    : undefined,
                              }}
                            >
                              {/*
                          `${row.firstName && row.firstName}  ${
                             row.lastName && row.lastName
                           }`
                         */}
                              {headerTitle === "Deactivated Accounts" &&
                              column.id == "name"
                                ? row.user.account_type === "Business"
                                  ? `${
                                      row.user && row.user.name && row.user.name
                                    }`
                                  : `${
                                      row.user &&
                                      row.user.first_name &&
                                      row.user.first_name
                                    }  ${
                                      row.user &&
                                      row.user.last_name &&
                                      row.user.last_name
                                    }`
                                : column.id === "name"
                                ? row.account_type === "Business"
                                  ? `${row.name && row.name}`
                                  : `${row.first_name && row.first_name}  ${
                                      row.last_name && row.last_name
                                    }`
                                : column.id == "state"
                                ? `${value},  ${
                                    headerTitle === "Deactivated Accounts"
                                      ? row?.user?.zipcode === "undefined"
                                        ? ""
                                        : row.zipcode
                                      : row.zipcode === "undefined"
                                      ? ""
                                      : row.zipcode
                                  }`
                                : column.id == "last_login"
                                ? `${value ? value : ""}`
                                : value}
                            </TableCell>
                          );
                        })}
                        <TableCell
                          key="forward"
                          align="right"
                          style={tableCellForward}
                        >
                          <ArrowForwardIosIcon
                            onClick={() =>
                              navigate("/users/details", {
                                state: {
                                  user: row,
                                  allUsers,
                                  suspended:
                                    headerTitle === "Deactivated Accounts"
                                      ? true
                                      : undefined,
                                },
                              })
                            }
                            style={{
                              color:
                                headerTitle === "Deactivated Accounts"
                                  ? "#FD355A"
                                  : "#C84B96",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <div style={{ margin: 8 }} />
                    </Fragment>
                  );
                })}
          </TableBody>
        </Table>
      )}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: 50 }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={
            headerTitle === "Registered Users"
              ? totalUsersTableDetails.total
              : headerTitle === "Business Accounts"
              ? businessUsersTableDetails.total
              : headerTitle === "Personal Accounts"
              ? personalUsersTableDetails.total
              : headerTitle === "Deactivated Accounts"
              ? allUsers.length
              : personalUsersTableDetails.total
          }
          rowsPerPage={
            headerTitle === "Registered Users"
              ? totalUsersRowsPage
              : headerTitle === "Business Accounts"
              ? businessUsersRowsPage
              : headerTitle === "Personal Accounts"
              ? personalUsersRowsPage
              : headerTitle === "Deactivated Accounts"
              ? rowsPerPage
              : personalUsersRowsPage
          }
          page={
            headerTitle === "Registered Users"
              ? totalUsersCurrentPage - 1
              : headerTitle === "Business Accounts"
              ? businessUsersCurrentPage - 1
              : headerTitle === "Personal Accounts"
              ? personalUsersCurrentPage - 1
              : headerTitle === "Deactivated Accounts"
              ? page
              : personalUsersCurrentPage - 1
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
       {headerTitle !== "Registered Users" ? <Button variant="outlined" style={exportButton}>
          <CSVLink
            data={allUsers && allUsers.length ? allUsers : []}
            headers={usersHeader}
            filename={`${
              headerTitle == "Deactivated Accounts"
                ? "Deactivated Users Record.csv"
                : headerTitle == "Business Accounts"
                ? "Business Users Record.csv"
                : headerTitle == "Personal Accounts"
                ? "Personal Users Record.csv"
                : "Users Record.csv"
            }`}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Export CSV
          </CSVLink>
        </Button>: null}
        { headerTitle === "Registered Users" ? <Button disabled={csvLoading} variant="outlined" style={exportButton} >
          <CSVLink
            data={allRegisteredUsers ?? []}
            headers={usersHeader}
            filename={'Users Record.csv'}
            style={{ textDecoration: "none", color: "#fff" }}
          >
            {csvLoading ? "Wait to Export..." : "Export as CSV"}
          </CSVLink>
        </Button>: null}
      </Grid>
    </Fragment>
  );
}

const exportButton = {
  marginLeft: "auto",
  background: "#C84B96",
  borderRadius: 12,
  width: "auto",
  height: 50,
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600,
};

const tableCellForward = {
  border: "none",
  background: "#F6F6F6",
  color: "#C84B96",
  fontSize: 14,
  fontWeight: 500,
  borderRadius: "0px 8px 8px 0px",
};
