import React, { useState } from "react";
import { Button, Grid, Modal } from "@mui/material";
import { Box } from "@mui/system";

import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import ButtonLoader from "../../ButtonLoader";
import { deleteBingoUser } from "../../../apis/games";
import { Toast } from "../../alert";

const UserDeleteModal = ({
  modalOpen,
  handleClose,
  currentUser,
  getUsersScores
}) => {
    const [loading, setLoading] =useState(false);

    const handleDeleteUser = async () => {
        setLoading(true);
        const apicall =await deleteBingoUser(currentUser);
        if(apicall.message === "User removed successfully"){
            Toast("success", "User removed successfully.");
            getUsersScores();
        }
        setLoading(false);
        handleClose()
    }

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid>
          <ReportGmailerrorredRoundedIcon
            style={{ color: "#8E2DE2", width: 50, height: 50 }}
          />
        </Grid>
        <span style={{ color: "#313137", fontSize: 20, fontWeight: 600 }}>
          Delete Quiz
        </span>
        <Grid container>
          <span style={messageText}>
            Are you sure you want to delete this User? Actions are not
            reversable.
          </span>
        </Grid>
        <Grid container>
          {loading ? (
            <Grid style={{ paddingLeft: 70 }}>
              <ButtonLoader />
            </Grid>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleDeleteUser}
              style={confirmButton}
            >
              Yes, I confirm
            </Button>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleClose}
            style={cancelButton}
          >
            No
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UserDeleteModal;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 456,
  bgcolor: "#fff",
  borderRadius: 6,
  p: 7,
  textAlign: "center",
};

const cancelButton = {
  width: "130px",
  marginLeft: "auto",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
  textTransform: "none",
};

const confirmButton = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
};

const messageText = {
  color: "#000000",
  fontSize: 15,
  fontWeight: 500,
  marginTop: 30,
  marginBottom: 30,
};
