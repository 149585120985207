// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bingo_gameHeadingBox__gjzP1 {\n    text-align: center;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 1rem;\n}\n.bingo_hide__6HQE0 {\n    display: none;\n}\n\n.bingo_task__U6Rvx:hover + .bingo_hide__6HQE0{\n    display: block ;\n     z-index: 99;\n}\n.bingo_hide__6HQE0:hover{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/games/bingo/bingo.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;KACd,WAAW;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".gameHeadingBox {\n    text-align: center;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 1rem;\n}\n.hide {\n    display: none;\n}\n\n.task:hover + .hide{\n    display: block ;\n     z-index: 99;\n}\n.hide:hover{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gameHeadingBox": "bingo_gameHeadingBox__gjzP1",
	"hide": "bingo_hide__6HQE0",
	"task": "bingo_task__U6Rvx"
};
export default ___CSS_LOADER_EXPORT___;
