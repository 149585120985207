import { Box, Typography } from '@mui/material'
import React from 'react'
import style from "./bingo.module.css";
import bingoImg from "../../../images/icons/Bingo_icon.png";
import UserScoreTableBingo from './UserScoreTableBingo';


const BingoGame = () => {
  return (
    <>
        <Box className={style.gameHeadingBox}>
                <img src={bingoImg} width={100} height={100} alt="logo-image"/>
                <Typography
                    component="h2"
                    sx={{ fontWeight: '700', fontSize: "2.25rem", color: "#313137" }}
                >
                    Bingo
                </Typography>
            </Box>
            <Box>
              <UserScoreTableBingo />
            </Box>
    </>
  )
}

export default BingoGame