import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getUsersScoreBingo } from '../../../apis/games';
import UserScoreDetailModal from './UserScoreDetailModal';
import ButtonLoader from '../../ButtonLoader';
import UserDeleteModal from './UserDeleteModal';

const UserScoreTableBingo = () => {
  const [usersScore, setUsersScore] =useState([]);
  const [detailModalOpen, setDetailModalOpen] =useState(false)
  const [deleteModalOpen, setDeleteModalOpen] =useState(false)
  const [currentUser, setCurrentUser] =useState(null);
  const [userLoading, setUserLoading] =useState(false);

  const BingoScoresColumns = [
    { id: "full_name", label: "USERNAME", minWidth: 200 },
    { id: "email", label: "EMAIL", minWidth: 200 },
    {id:'details', label:"DETAILS",minWidth: 200},
    {id:'delete', label:"DELETE",minWidth: 200}
  ];


  const getUsersScores = async () =>{
    setUserLoading(true);
    const data = await getUsersScoreBingo();
    setUsersScore(data);
    setUserLoading(false);
  }

  const handleDetailModalOpen = (user_id) =>{
    setCurrentUser(user_id);
    setDetailModalOpen(true)
  }
  const handleDetailModalClose = () =>{
    setDetailModalOpen(false);
  }
  const handleDeleteModalOpen = (user_id) =>{
    setCurrentUser(user_id);
    setDeleteModalOpen(true);
  }
  const handleDeleteModalClose = () =>{
    setDeleteModalOpen(false);
  }
  useEffect(()=>{
    getUsersScores()
  },[])

  return (
    <>
    <Grid >
      <Table>
        <TableHead>
          <TableRow>
            {BingoScoresColumns?.map((item)=>(
              <TableCell key={item.id} style={{
                      border: "none",
                      fontSize: 12,
                      fontWeight: 600,
                      color: "#8086A2",
                    }}>{item.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {userLoading ? <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell><ButtonLoader/></TableCell>
          <TableCell></TableCell>
        </TableRow> :
          (usersScore?.map((user_item)=>(
            <TableRow key={user_item?.id}>{
              BingoScoresColumns?.map((column) => (
                <TableCell key={column?.id}>{
                  column?.id === "details" ? <Button onClick={()=> handleDetailModalOpen(user_item?.user_id)}>Details</Button> :
                  column?.id === "delete" ?<Button onClick={()=> handleDeleteModalOpen(user_item?.user_id)}>Delete</Button> :user_item[column?.id]
                }</TableCell>
              ))
            }</TableRow>
          )))
        }
        </TableBody>
      </Table>
    </Grid>
    {
      detailModalOpen && <UserScoreDetailModal modalOpen={detailModalOpen} handleClose={handleDetailModalClose} currentUser={currentUser}/>
    }
    {
      deleteModalOpen && <UserDeleteModal modalOpen={deleteModalOpen} handleClose={handleDeleteModalClose} currentUser={currentUser} getUsersScores={getUsersScores}/>
    }
    </>
  )
}

export default UserScoreTableBingo;