import React, {useState } from "react";
import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import style from "./game.module.css";
import { updateCurrentActiveWordCall } from "../../apis/admins";
import { Toast } from "../../components/alert";
import { modalStyle } from "../../components/constants";

const AddGameWebsiteModal = (props) => {
  const {modalOpen, handleClose, handleChange, words, getAllGameWordList } = props
  const [isSubmit, setIsSubmit] = useState(false);
  const [websiteError, setWebsiteError] =useState(false);

  const handleGetWord = (e) => {
        const wordVal = e?.target?.value;
        handleChange({...words, website: wordVal });
        const isTextValid = checkWebsiteLink(e?.target?.value);
         if(!isTextValid){
          setWebsiteError(true);
         }else{
          setWebsiteError(false);
         }
    };

  const checkWebsiteLink =(word)=>{
    const regexWebsite =/\./; 
    if(regexWebsite.test(word)){
      return true
    }
    return false
  }

  const handleSubmitForm = async (e) => {
        e.preventDefault();
        const isTextValid = checkWebsiteLink(e?.target[0]?.value);
         if(!isTextValid){
          const toastMessage = "Please fill a valid site!";
                Toast("error", toastMessage);
                return;
          // setIsWebsiteValid(false);
         }
        setIsSubmit(true);
        try {
            const apiBody = {website : words.website,
                _method : "PUT"}
            const response = await updateCurrentActiveWordCall(words.id,apiBody);
            if (response) {
              const toastMessage = "your word Website added successfully!";
                Toast("success", toastMessage);
                handleChange({});
                setIsSubmit(false);
                getAllGameWordList();
            }
        } catch (error) {
            console.log(error);
        } finally {
          handleClose();
        }
    };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        handleClose();
      }}
    >
      <Box sx={modalStyle}>
      <form className={style.formBox} onSubmit={handleSubmitForm}>
            <h2>Add Your Word website</h2>
            <TextField
                type="text"
                placeholder="Add Here..."
                size="small"
                fullWidth
                value={words.website}
                onChange={handleGetWord}
                error={websiteError}
                helperText={websiteError ? "Please fill a valid site!": ""}
                inputProps={{
                  maxLength:'80'
                }}
            />
            <Box className={style.btnBox}>
                {<Button
                    variant="outlined"  
                    color="secondary"
                    fullWidth
                    onClick={handleClose}
                    style={{ border: "1px solid #c84b96", color: "#c84b96" }}
                >
                    Cancel
                </Button>}
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ background: "#c84b96" }}
                    type="submit" 
                    fullWidth
                    disabled={websiteError || isSubmit ? true : false}
                >
                    {words.website === "" ? "Add" : "Update" }
                </Button>
            </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddGameWebsiteModal;

const titleStyles = { color: "#151E24", fontSize: 20, fontWeight: 700 };

const confirmButtonStyles = {
  width: "200px",
  textTransform: "none",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  background: "#C84B96",
  color: "#FFFFFF",
  borderRadius: 8,
  marginLeft: "auto",
};

const cancelButtonStyles = {
  width: "130px",
  height: 55,
  fontSize: 15,
  fontWeight: 600,
  color: "#313137",
  borderColor: "#313137",
  borderRadius: 8,
  textTransform: "none",
};
