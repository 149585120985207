import { API } from "../constants";
import { request } from "../constants/request";

export const getAllUserGames = () => {
    return request({
      url: API.baseUrl + API.getGameUser,
      method: "GET",
    });
  };

export const getWordDetail =(word_id) =>{
  return request({
    url: API.baseUrl + API.getWordAnalytics + `/${word_id}`,
    method:"GET",
  })
}

export const getUsersScoreBingo = () =>{
  return request({
    url: API.baseUrl + API.getUsersBingo,
    method: 'GET',
  })
}

export const getUserScoreBingo = (user_id) =>{
  return request({
    url: API.baseUrl + "/api/bingo" + `/${user_id}` + "/details",
    method: 'GET',
  })
}

export const deleteBingoUser = (user_id) =>{
  return request({
    url: API.baseUrl + "/api/bingo" + `/${user_id}` + "/remove",
    method: 'DELETE',
  })
}

export const updateTaskStatus = (data) =>{
  return request({
    url: API.baseUrl + API.updateTaskStatus,
    method: 'POST',
    data:data
  })
}