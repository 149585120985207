import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getUserScoreBingo, updateTaskStatus } from '../../../apis/games';
import bingoImg from "../../../images/icons/Bingo_icon.png";
import ButtonLoader from '../../ButtonLoader';
import styles from "./bingo.module.css"
const UserScoreDetailModal = ({modalOpen, handleClose, currentUser}) => {
  const [usersScore, setUsersScore] =useState([]);
  const [dataLoading, setDataLoading] =useState(false);

  const getUserDetailCall = async (user_id) =>{
    setDataLoading(true);
    const data = await getUserScoreBingo(user_id);
    setUsersScore(data);
    setDataLoading(false);
  }
  const handleChangeParticularTask =async (task_id)=>{
    setDataLoading(true);
    const data = await updateTaskStatus({
      bingo_task_id : task_id,
      user_id: currentUser,
  });
  if(data.message === "Task marked as completed"){
    getUserDetailCall(currentUser);
  }else{
    setDataLoading(false);
  }
  }

  useEffect(()=>{
    getUserDetailCall(currentUser)
  },[currentUser])
  return (
    <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={modalStyle}>
        <Typography
                    component="h4"
                    sx={{ fontWeight: '500', fontSize: "2rem", color: "#313137" }}
                >
                    User Scores
                </Typography>
                <Box sx={{marginTop:"1rem"}}>
                {dataLoading ?<Box sx={{ height:"500px", display:"flex", alignItems:"center", justifyContent:"center"}}><ButtonLoader/></Box>  : <Grid container sx={{borderRadius:"4px", overflow:"hidden", padding:"0"}} spacing={1}>
                  {usersScore.map((item)=>{
                    if(item.id !== 13){
                      return (
                        <Grid key={item?.id} xs={2.4} item sx={{position:"relative",display:"flex", padding:"0 !important", alignItems:"center",justifyContent:"center", width:"auto", height:"100px", backgroundColor:"#3f173a", ...(item?.completed === true &&greenBoxStyle)}}>
                        <div className={ item?.completed === true ? {}:styles.task} style={{height:"100%", weight:"100%", position:"absolute", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Typography sx={{color:"white"}}>{item?.task}</Typography>
                        </div>
                        <div className={styles.hide} style={purpleBtnStyle}>
                          <Button onClick={()=> handleChangeParticularTask(item?.id)}>Change Status</Button>
                        </div>
                  </Grid>
                  )
                    }else{
                      return (<>
                        <Grid key={item?.id} xs={2.4} item sx={{padding:"0 !important",display:"flex", alignItems:"center",justifyContent:"center", width:"100px", height:"100px", backgroundColor:"#3f173a"}}>
                        <img src={bingoImg} style={imageStyle} alt="bingo_icon" />
                         </Grid>
                        <Grid key={item?.id} xs={2.4} item sx={{position:"relative", display:"flex",padding:"0 !important", alignItems:"center",justifyContent:"center", width:"100px", height:"100px", backgroundColor:"#3f173a", ...(item?.completed === true && greenBoxStyle)}}>
                        <div className={ item?.completed === true ? {}:styles.task} style={{height:"100%", weight:"100%", position:"absolute", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Typography sx={{color:"white"}}>{item?.task}</Typography>
                        </div>
                        <div className={styles.hide} style={purpleBtnStyle}>
                          <Button onClick={()=>handleChangeParticularTask(item?.id)}>Change Status</Button>
                        </div>
                  </Grid>
                  </>
                      )}
                  })}
                  </Grid>}
                  <Grid container sx={{marginTop:"1rem", flexDirection:"row-reverse"}}>
                    <Grid item> 
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={handleClose}
                      style={{
                        width: "130px",
                        marginLeft: "auto",
                        height: 55,
                        fontSize: 15,
                        fontWeight: 600,
                        color: "#313137",
                        borderColor: "#313137",
                      }}
                    >
                      Close
                    </Button>
                    </Grid>
                  </Grid>
                </Box>
        </Box>
    </Modal>
  )
}

export default UserScoreDetailModal;

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    minWidth:625,
    bgcolor: "#fff",
    borderRadius: 6,
    p: 4,
    textAlign: "center",
  };

  const greenBoxStyle = {
    background: "radial-gradient( #5cb85c, #3f173a)",
  }
  const imageStyle ={width:"inherit", borderRadius:'48px'}
  const purpleBtnStyle =  {height:"100%", weight:"100%", position:"absolute", background:"#3f173a"}
  const gridItemStyles ={height:"100%", weight:"100%", position:"absolute", display:"flex", justifyContent:"center", alignItems:"center"}